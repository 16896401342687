<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button label="Nova Usina" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
              <Button label="Dados Técnicos" icon="pi pi-plus" class="p-button-warning mr-2" @click="openDados" />

            </div>
          </template>

          <template v-slot:end>
            <!-- <FileUpload
              mode="basic"
              accept="image/*"
              :maxFileSize="1000000"
              label="Import"
              chooseLabel="Import"
              class="mr-2 inline-block"
            />-->
            <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
          </template>
        </Toolbar>

        <DataTable exportFilename="relatorio[USINAS]" csvSeparator=";" ref="dt" :value="usinas"
          v-model:selection="selectedUnidades" dataKey="id" :paginator="true" :rows="5" :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
          responsiveLayout="scroll">
          <template #header>
            <div class="
                        flex flex-column
                        md:flex-row md:justify-content-between md:align-items-center
                      ">
              <h5 class="m-0">Gerenciar Usina</h5>
              <span class="">
                <!-- <Dropdown class="mr-2" id="status" v-model="id_usina" filter :options="usinasCombo" optionLabel="label"
                  optionValue="value" placeholder="Filtrar por Usina">
                </Dropdown>



                <Button label="Buscar" icon="pi pi-search" class="p-button-secondary p-button-sm" @click="filtrar()" />
 -->
              </span>
              <span class="block mt-2 md:mt-0 p-input-icon-left">


                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar" />
              </span>
            </div>
          </template>

          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          <Column field="id" header="ID" :sortable="true" headerStyle="width:14%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">id</span>
              {{ slotProps.data.id }}
            </template>
          </Column>

          <Column field="razao_social" header="Razão Social" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Razão Social</span>
              {{ slotProps.data.razao_social }}
            </template>
          </Column>
          <Column field="sigla" header="Sigla" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Sigla</span>
              {{ slotProps.data.sigla }}
            </template>
          </Column>
          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                @click="editUnidade(slotProps.data)" />
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2"
                @click="apagarUnidadeModal(slotProps.data)" />
            </template>
          </Column>
        </DataTable>

        <Dialog v-model:visible="UsinaDialog" :style="{ width: '900px' }" header="Gerenciar Usina" :modal="true"
          class="p-fluid">
          <div class="grid">
            <div class="col-6">
              <div class="field">
                <label for="estado">Razão Social</label>
                <InputText id="razao_social" v-model.trim="usina.razao_social" autofocus />
              </div>
            </div>

            <div class="col-6">
              <div class="field">
                <label for="sigla">Sigla</label>
                <InputText id="sigla" v-model.trim="usina.sigla" autofocus />
              </div>
            </div>

            <div class="col-4">
              <div class="field">
                <label for="estados">Estado</label>
                <Dropdown id="estados" v-model="usina.id_estado" :options="estados" optionLabel="label"
                  placeholder="Selecione um estado" optionValue="value" @change="trocarCidades()" :filter="true">
                </Dropdown>
              </div>
            </div>

            <div class="col-4">
              <div class="field">
                <label for="cidade">Cidade</label>
                <Dropdown id="status" v-model="usina.id_cidade" :options="cidades" optionLabel="label" optionValue="value"
                  placeholder="Selecione uma cidade" :filter="true">
                </Dropdown>
              </div>
            </div>

            <div class="col-4">
              <div class="field">
                <label for="cnpj">CNPJ</label>
                <InputText id="cnpj" v-model.trim="usina.cnpj" autofocus />
              </div>
            </div>

            <h5
              style="display: inline-block;width: 100%; font-size: 16px; padding: 20px 0; margin:0 0 0 5px;border-top: 1px solid #eee;;">
              Dados Técnicos </h5>

            <div class="col-3">
              <div class="field">
                <label for="volume_mwp">Volume (MWp)</label>
                <InputText id="volume_mwp" v-model.trim="usina.volume_mwp" autofocus />
              </div>
            </div>

            <div class="col-3">
              <div class="field">
                <label for="volume_mwac">Volume (MWac)</label>
                <InputText id="volume_mwac" v-model.trim="usina.volume_mwac" autofocus />
              </div>
            </div>

          </div>

          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="hideDialog" />
            <Button label="Salvar" icon="pi pi-check" class="p-button-success p-button-sm" @click="salvarUnidade" />
          </template>
        </Dialog>

        <Dialog v-model:visible="deleteUsinaDialog" :style="{ width: '450px' }" :modal="true" header="&nbsp;">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="usina">Apagar: <b>{{ usina.sigla }}</b>?</span>
          </div>
          <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm"
              @click="deleteUsinaDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-danger p-button-sm" @click="apagarUnidade" />
          </template>
        </Dialog>

        <Dialog v-model:visible="deleteUnidadesDialog" :style="{ width: '450px' }" header="" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="unidades">Apagar unidades selecionadas?</span>
          </div>
          <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="deleteUnidadesDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteSelectedUnidades" />
          </template>
        </Dialog>

        <Dialog v-model:visible="modaldados" :style="{ width: '90%' }" header="Gerenciar Dados Técnicos" :modal="true">

          <dadosTecnicos></dadosTecnicos>
        </Dialog>


      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import dadosTecnicos from "./dadostecnicos";
export default {

  data() {
    return {
      id_usina: "",
      modaldados: false,
      prioridades: [
        { label: "0", value: "0" },
        { label: "1", value: "1" },
        { label: "2", value: "2" }
      ],
      prioridade: "",
      unidades: [],
      usinasCombo: [],
      usinas: [],
      UsinaDialog: false,
      deleteUsinaDialog: false,
      deleteUnidadesDialog: false,
      usina: {},
      selectedUnidades: null,
      filters: {},
      submitted: false,
      status: 0,
      empresas: [],
      areas: [],
      regionais: [],
      tipos: [],
      estados: [],
      cidades: [],
      id_estado: "",
      id_cidade: "",
      statuses: [
        { label: "Inativo", value: "0" },
        { label: "Ativo", value: "1" },
      ],
      concessionarias: [],
      tipos_tensao: [],
      tipos_modalidades: [],
      tipos_tensao_fornecimento: [],
      classes_tarifaria: [],
      sub_classes: [],
    };
  },
  UnidadeService: null,
  created() {
    this.initFilters();
  },
  mounted() {

    this.listarUsinas();

    //this.UnidadeService.getUnidades().then((data) => (this.Unidades = data));
  },
  methods: {
    openDados() {
      this.modaldados = true;
    },
    filtrar() {
      let dados = {
        id_usina: this.id_usina,
      }
      this.msg = "Aguarde, listando resultados."
      this.axios
        .post("/usinas/listar", dados)
        .then((response) => {
          
          this.usinas = response.data.dados
        })
        .catch((error) => {
          console.log(error);
        });
    },
    trocarCidades() {
      console.log(this.usina.id_estado);
      this.listarCidades(this.usina.id_estado);
    },
    listarUsinas() {
      this.axios
        .post("/usinas/listar")
        .then((response) => {

          this.usinas = response.data.dados
          console.log(response.data.dados)
          let options = [];

          let res = response.data.dados;
          Object.keys(res).forEach(ley => {
            console.log(ley);
            let option = { value: res[ley]["id"], label: res[ley]["razao_social"] };
            options.push(option);
          });


          this.usinasCombo = options;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    listarCidades() {

      let data = {
        state_id: this.usina.id_estado,
      };
      this.axios
        .post("/auxiliar/cidades", data)
        .then((response) => {
          console.log(response);
          let options = [];
          response.data.dados.map((el) => {
            let option = { value: el["id"], label: el["nome"] };
            options.push(option);
          });
          this.cidades = options;
          console.log(this.usina.id_cidade);
          let set = this.usina.id_cidade;
          this.usina.id_cidade = set;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listarEstados() {
      this.axios
        .post("/auxiliar/estados")
        .then((response) => {
          console.log(response);
          let options = [];
          response.data.dados.map((el) => {
            let option = { value: el["id"], label: el["nome"] };
            options.push(option);
          });
          this.estados = options;
          this.id_estado = 1;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    trocarStatus() {
      this.usina.status = this.status.value;
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      return;
    },
    openNew() {



      //this.listarTiposTensao();
      this.listarEstados();
      this.listarCidades();

      this.usina = {};
      this.submitted = false;
      this.UsinaDialog = true;
    },
    hideDialog() {
      this.UsinaDialog = false;
      this.submitted = false;
    },
    salvarUnidade() {
      this.submitted = true;

      if (this.usina.id) {
        this.axios
          .post("/usinas/editar", this.usina)
          .then(() => {
            this.UsinaDialog = false;
            this.usina = {};

            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Usina salvs com sucsso!",
              life: 3000,
            });
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });



        this.listarUsinas();

      } else {

        this.axios
          .post("/usinas/adicionar", this.usina)
          .then(() => {

            this.listarUsinas();

            this.UsinaDialog = false;
            this.usina = {};

            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Usina salva com sucsso!",
              life: 3000,
            });
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      }
    },
    editUnidade(usina) {
      this.UsinaDialog = true;
      this.usina = { ...usina };
      this.listarEstados();
      this.listarCidades();
    },
    apagarUnidadeModal(usina) {
      this.usina = usina;
      this.deleteUsinaDialog = true;
    },
    apagarUnidade() {
      this.axios
        .post("/usinas/apagar", this.usina)
        .then(() => {
          this.listarUsinas();
          this.deleteUsinaDialog = false;
          this.usina = {};
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Usina apagada com sucsso!",
            life: 3000,
          });
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.Unidades.length; i++) {
        if (this.Unidades[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteUnidadesDialog = true;
    },
    deleteSelectedUnidades() {
      let obj = this.selectedUnidades;
      Object.keys(obj).forEach((key) => {
        this.axios
          .post("/unidades/apagar", obj[key])
          .then(() => {
            this.listarUnidade();
            this.deleteUsinaDialog = false;
            this.usina = {};
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Usário apagado com sucesso!s",
              life: 3000,
            });
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      });

      this.deleteUnidadesDialog = false;
      this.selectedUnidades = null;
      this.$toast.add({
        severity: "success",
        summary: "Sucesso",
        detail: "Unidades apagada com sucesso!",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
  components: {
    dadosTecnicos: dadosTecnicos
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>
